import numeral from "numeral";

export function currencyFormatter(params) {
    return numeral(params.value).format("0,0");
}

export const defaultColDef = {
    width: 100,
    resizable: true,
    sortable: true,
    filter: false,
    autoHeaderHeight: true
}

export const resultBoolean = (value) => {
    return value === 1 || value === '1';
}

export function convertBoolean(value) {
    // 숫자 0을 false로 변환
    if (value === 0) {
        return false;
    }

    // 숫자 1을 true로 변환
    if (value === 1) {
        return true;
    }

    // 불리언 true를 1로 변환
    if (value === true) {
        return 1;
    }

    // 불리언 false를 0으로 변환
    if (value === false) {
        return 0;
    }

    // 입력값이 0, 1, true, false가 아닐 경우 null 반환
    return null;
}

export function exportUserStatus(status) {
    switch (status) {
        case 0: return "요청"
        case 1: return "정상"
        case 2: return "정지"
        case 3: return "탈퇴"
        case 9: return "테스트"
    }
}

export function exportExchangeStatus(status) {
    switch (status) {
        case 0: return "요청"
        case 1: return "대기"
        case 8: return "거절"
        case 9: return "완료"
    }
}

/**
 * 경기명 치환
 */
export const changeSportName = (sportCode) => {
    let sportName = ""
    switch (sportCode) {
        case 'soccer':
            sportName = "축구"
            break;
        case 'esports':
            sportName = "E스포츠"
            break;
        case 'baseball':
            sportName = "야구"
            break;
        case 'basketball':
            sportName = "농구"
            break;
        case 'volleyball':
            sportName = "배구"
            break;
        case 'tabletennis':
            sportName = "탁구"
            break;
        case 'icehockey':
            sportName = "하키"
            break;
        case 'americanfootball':
            sportName = "미식축구"
            break;
        case 'boxingufc':
            sportName = "UFC/복싱"
            break;
        case 'handball':
            sportName = "핸드볼"
            break;
        default:
            sportName = sportCode
            break;
    }
    return sportName
}